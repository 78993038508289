import './src/styles/__base.scss';
import { initAmplitude } from './src/utils/amplitude';
import { isBrowser } from './src/utils/ssr';
import { initAxios } from './src/utils/api';

export const onInitialClientRender = () => {
	if (isBrowser) {
		initAmplitude();
		initAxios(process.env.GATSBY_API_URL, {});
	}
};
